<template>
  <Layout>
    <!-- <el-tabs
      v-model="formData.zoneId"
      @tab-click="handleChangeTabs"
    >
      <el-tab-pane
        :label="zoneList.length ? zoneList[0].name : ''"
        :name="zoneList.length ? String(zoneList[0].id) : ''"
      />
      <el-tab-pane
        v-if="zoneList.length"
        :label="zoneList[zoneList.length - 1].name"
        :name="String(zoneList[zoneList.length - 1].id)"
      />
      <el-tab-pane
        v-for="(item, index) in zoneList"
        :key="index"
        :label="item.name"
        :name="String(item.id)"
      />
    </el-tabs> -->
    <BHeader>
      <el-select
        v-model="formData.status"
        placeholder="上架状态"
        clearable
        @change="handleSearch"
      >
        <el-option
          v-for="item in categoryStateList"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        />
      </el-select>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleCreateCategory"
        >
          新建类目
        </el-button>
      </template>
    </BHeader>

    <el-table
      v-loading="categoryInfo.loading"
      :data="categoryInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="类目ID"
      />
      <el-table-column
        prop="sort"
        label="排序"
      />
      <!--      <el-table-column-->
      <!--        prop="key"-->
      <!--        label="类目ID"-->
      <!--      />-->
      <el-table-column
        prop="title"
        label="类目名称"
      >
        <template v-slot="{ row }">
          {{ (row.titleDetail || {}).en || "未知" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="modelSum"
        label="数量"
      >
        <template v-slot="{ row }">
          <el-button
            type="text"
            @click="handleManageItem(row)"
          >
            {{ row.modelSum }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="state"
        min-width="100px"
        label="上架状态"
      >
        <template v-slot="{ row }">
          {{ (categoryStateMap[row.status] || {}).name || "UNKNOWN" }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="220"
        label="操作"
      >
        <template v-slot="{ row }">
          <BTextButton
            title="管理类目"
            icon="fa fa-wrench"
            @click="handleManageItem(row)"
          />
          <BTextButton
            title="编辑类目信息"
            icon="fa fa-pencil"
            @click="handleEditCategory(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="row.status === 1 ? '启用' : '禁用'"
            :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban'}`"
            @click="handleToggleCategoryItem(row)"
          />
          <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteCategoryItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="categoryInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryCategoryList"
    />
  </Layout>
</template>

<script>
import { categoryStateList, categoryStateMap } from '@/utils/selectOptions'
import {
  queryCategoryList,
  deleteCategoryItem,
  toggleCategoryItem,
  getNationAreaList
} from '@/api/APP_Category'
import { mapState } from 'vuex'
export default {
  name: 'CategoryList',
  data () {
    return {
      lastZoneId: '1',
      categoryStateList,
      categoryStateMap,
      formData: {
        zoneId: '5',
        pId: 0,
        status: '',
        pageNum: 1,
        pageSize: 20
      },
      categoryInfo: {
        list: [],
        loading: false,
        total: 0
      },
      zoneList: []
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel', 'pkgType'])
  },
  created () {
    this.getNationAreaList().then(() => {
      this.queryCategoryList()
    })
  },
  activated () {
    this.getNationAreaList().then(() => {
      this.queryCategoryList()
    })
  },
  methods: {
    getNationAreaList () {
      return getNationAreaList().then(res => {
        if (res.code === 200) {
          const zoneListMap = res?.data?.zoneList
          console.log(zoneListMap, 'zoneListMap')
          this.zoneList = Object.keys(zoneListMap).map(key => {
            return zoneListMap[key]
          })
          if (this.zoneList.length) {
            const activeItem = this.zoneList[0]
            this.formData.zoneId = 5
            this.lastZoneId = String(activeItem.id)
          }
        }
      })
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryCategoryList()
    },
    queryCategoryList () {
      this.categoryInfo.loading = true
      queryCategoryList({
        ...this.formData,
        appId: this.appId,
        channel: this.channel,
        pkgType: this.pkgType,
        zoneId: '5'
      })
        .then(res => {
          if (res.code === 200) {
            this.categoryInfo.list = res.data.list
            console.log(this.categoryInfo.list, 'this.categoryInfo.list')
            this.categoryInfo.total = res.data.total
          }
        })
        .finally(() => {
          this.categoryInfo.loading = false
        })
    },
    // handleChangeTabs () {
    //   if (this.lastZoneId === this.formData.zoneId) {
    //     return false
    //   }
    //   this.lastZoneId = this.formData.zoneId
    //   this.formData.pageNum = 1
    //   this.queryCategoryList()
    // },
    handleCreateCategory () {
      this.$router.push({
        name: 'CategoryCreate',
        query: {
          zoneId: this.formData.zoneId
        }
      })
    },
    handleEditCategory (row) {
      this.$router.push({
        name: 'CategoryEdit',
        query: {
          id: row.id,
          zoneId: this.formData.zoneId
        }
      })
    },
    handleManageItem (row) {
      this.$router.push({
        name: 'CategoryItemList',
        query: {
          id: row.id,
          zoneId: this.formData.zoneId,
          name: row.titleDetail.en
        }
      })
    },
    handleDeleteCategoryItem (row) {
      this.$confirm('此操作将删除模块, 是否继续', '提示', {
        type: 'warning '
      }).then(() => {
        this.$set(row, 'deleteLoading', true)
        deleteCategoryItem({
          id: row.id
        })
          .then(res => {
            if (res.code === 200) {
              this.$message.success('删除成功')
              this.queryCategoryList()
            }
          })
          .finally(() => {
            this.$set(row, 'deleteLoading', false)
          })
      })
    },
    handleToggleCategoryItem (row) {
      const TIPS =
        row.status !== 1
          ? '此操作将启用模块， 是否继续?'
          : '此操作将禁用模块， 是否继续?'
      const NEXT_STATUS = row.status !== 1 ? 1 : 2
      this.$confirm(TIPS, '提示', { type: 'warning ' }).then(() => {
        this.$set(row, 'loading', true)
        toggleCategoryItem({
          id: row.id,
          status: NEXT_STATUS
        })
          .then(res => {
            if (res.code === 200) {
              this.$set(row, 'status', NEXT_STATUS)
            }
          })
          .finally(() => {
            this.$set(row, 'loading', false)
          })
      })
    }
  }
}
</script>

<style scoped></style>
